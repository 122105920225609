<div id="column-slide" class="visible">
  <div id="logo-block-bg" class="logo-block-bg-full"></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="services-header">
    скусство управления<br />частным капиталом
    <div id="page-header-comment">Депозитарное обслуживание</div>
  </div>
  <div id="depositary-slide"></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Услуги &nbsp;/&nbsp;Депозитарное обслуживание
  </div>

  <div id="logo-block-bg-b" class="hidden"></div>
</div>

<div id="column" class="visible">
  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">Услуги</div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.2s"
      data-wow-offset="100"
      style="
        animation-delay: 0.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>1.</div></div>
      <div class="advantage-info">
        Учет и удостоверение прав собственности на ценные бумаги
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.4s"
      data-wow-offset="100"
      style="
        animation-delay: 0.4s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>2.</div></div>
      <div class="advantage-info">
        Ведение отдельного от других счета депо депонента
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>3.</div></div>
      <div class="advantage-info">
        Учет и удостоверение передачи прав на ценные бумаги, включая случаи
        обременения ценных бумаг обязательствами
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.8s"
      data-wow-offset="100"
      style="
        animation-delay: 0.8s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>4.</div></div>
      <div class="advantage-info">
        Регистрация в качестве номинального держателя ценных бумаг клиентов в
        реестре владельцев именных ценных бумаг или у другого депозитария
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.0s"
      data-wow-offset="100"
      style="animation-delay: 1s; animation-name: visible; visibility: visible"
    >
      <div class="advantage-block"><div>5.</div></div>
      <div class="advantage-info">
        Ведение счетов депо различных категорий депонентов: владельцев,
        доверительных управляющих, номинальных держателей
      </div>
    </div>
    <div class="clear"></div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="100"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>6.</div></div>
      <div class="advantage-info">
        Услуги, содействующие реализации владельцами ценных бумаг их прав по
        ценным бумагам: представление интересов клиента на общих собраниях
        акционеров (по доверенности), получение дивидендов, доходов и иных
        платежей по ценным бумагам
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.4s"
      data-wow-offset="100"
      style="
        animation-delay: 1.4s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>7.</div></div>
      <div class="advantage-info">
        Оперативное предоставление информации от эмитентов и регистраторов,
        включая передачу бюллетеней для голосования и иных документов
        (информации), необходимых для участия в общих собраниях акционеров
        эмитента
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.6s"
      data-wow-offset="100"
      style="
        animation-delay: 1.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>8.</div></div>
      <div class="advantage-info">Операции с иностранными ценными бумагами</div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.8s"
      data-wow-offset="100"
      style="
        animation-delay: 1.8s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>9.</div></div>
      <div class="advantage-info">Проведение корпоративных действий</div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="2.0s"
      data-wow-offset="100"
      style="animation-delay: 2s; animation-name: visible; visibility: visible"
    >
      <div class="advantage-block"><div>10.</div></div>
      <div class="advantage-info">
        Присвоение иностранным ценным бумагам CFI кодов (через центральный
        депозитарий)
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <div class="infoblock-black-opacity-l">
        <div class="infoblock-header-d">Конфиденциальность</div>
        <div class="infoblock-text-c">
          Депозитарий обеспечивает конфиденциальность информации о лице,
          которому открыт счет депо, а также информации о таком счете и
          операциях по нему.
        </div>
      </div>
      <div class="depositary-bg-l">
        <div class="company-dep-header company-header-hide">
          <div class="double-border center-block">Конфиденциальность</div>
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="infoblock-black-opacity-r">
        <div class="infoblock-header-d">Прозрачность</div>
        <div class="infoblock-text-c">
          Дивиденды и купонные платежи поступают в депозитарий на специальный
          депозитарный счет в банке и перечисляются по реквизитам, которые
          клиент указал в анкете депонента.
        </div>
      </div>
      <div class="depositary-bg-r">
        <div class="company-header company-header-hide">
          <div class="double-border center-block">Прозрачность</div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="strategy-header" data-wow-offset="200">Документы</div>

    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="animation-delay: 0.6s; animation-name: none; visibility: visible; opacity: 1;"
    >
      <div class="advantage-block doc-link">Тарифы на услуги депозитария</div>
      <div class="advantage-info">
        Актуально с 22.09.2022 по настоящее время <br /><br />Дата размещения
        25.01.2023 <br /><br /><a
          href="/docs/tariffs_depositary_2022_09.pdf"
          class="button-link-doc"
          >Скачать</a
        >
      </div>
    </div>

    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="animation-delay: 0.6s; animation-name: none; visibility: visible; opacity: 1;"
    >
      <div class="advantage-block doc-link">
        Условия осуществления депозитарной деятельности
      </div>
      <div class="advantage-info">
        Актуально с 03.07.2023 по настоящее время <br /><br />Дата размещения
        28.06.2023 <br /><br /><a
          href="/docs/reglament_depositary_2023_07.pdf"
          class="button-link-doc"
          >Скачать</a
        >
      </div>
    </div>

    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="animation-delay: 0.6s; animation-name: none; visibility: visible; opacity: 1;"
    >
      <div class="advantage-block doc-link">
        Лицензия на осуществление депозитарной деятельности
      </div>
      <div class="advantage-info">
        Актуально с 21.06.2011 по настоящее время <br /><br />Дата размещения
        13.05.2016 <br /><br /><a
          href="/docs/depositary_lic.pdf"
          class="button-link-doc"
          >Скачать</a
        >
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="advantage-block link-all-docs" style="margin-top: 0">
      <a href="/depositary_information.html" class="doc-link"
        >Все документы по Депозитарной деятельности →</a
      >
    </div>
  </div>

  <div class="about-advantage">
    <a href="/corp_actions_news.html" class="awards-header"
      >Корпоративные действия эмитентов</a
    >
  </div>

  <app-footer></app-footer>
</div>
