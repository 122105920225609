<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Контакты для СМИ</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/licenses/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/press.html">Пресс-центр</a>

    &nbsp;/&nbsp;Контакты для СМИ
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="about-points">
    <div class="col30 press-pr-info">
      <div>Контакты для СМИ</div>
      Уважаемые коллеги! Инвестиционная компания General Invest работает на
      российском и международном финансовых рынках.
      <br /><br />Наши спикеры с удовольствием поделятся с вами своими знаниями.
      Они помогут в подготовке материалов на финансовую тематику и
      прокомментируют текущую экономическую ситуацию.
    </div>

    <div class="col60 press-pr-block">
      <div class="pr-photo-block">
        <div class="team-item-photo">
          <img src="../../../assets/team/y-griberman.png" alt="" />
        </div>
      </div>
      <div class="pr-item-info">
        <div class="team-name">Юлия Гриберман</div>
        <div class="team-position">
          Директор по связям с общественностью и маркетингу
        </div>
        +7 (495) 727-00-77 (доб. 238)
        <br />+7 (909) 64-64-272 <br /><br /><a
          href="mailto:jgriberman@generalinvest.ru"
          >jgriberman@generalinvest.ru</a
        >
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
