<div id="column-slide" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="services-header">
    Станьте<br />участником Deliclub<br />с General Invest
    <div id="page-header-comment">Получите привилегии в Delimobil</div>
  </div>
  <div id="deliclub-slide"></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Услуги &nbsp;/&nbsp;Деликлуб
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="quote-block-g deliclub">
    <div id="quote-column">
      <div
        id="quote-photo"
        style="
          background: url('../../../assets/deliclub/delimobil-logo-icon.png') center center
            no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        class="wow up-anim"
        data-wow-offset="300"
      ></div>
      <div
        id="quote-text-block"
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div id="quote-text">
          Делимобиль — это крупнейший оператор каршеринга, сервиса краткосрочной
          аренды автомобиля с поминутной оплатой, который имеет автопарк более
          24 тысяч машин в 10 городах. <br /><br />Делимобиль стала первой
          компанией, предоставляющей услуги каршеринга, которая разместила акции
          на Московской бирже в феврале 2024 года. <br /><br />Для своих
          акционеров компания предлагает специальные пакеты привилегий в
          зависимости от количества приобретенных ценных бумаг.
        </div>
        <div id="quote-name">
          <div>General Invest</div>
          Вступи в клуб с проверенным брокером
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">
      Преимущества в Делимобиле
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>1.</div>
        Кэш-бэк бонусами (до 15 %)
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>2.</div>
        До 15 дополнительных минут бесплатного бронирования
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>3.</div>
        Приоритетное обслуживание
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>4.</div>
        Доступ к автомобилям класса Суперстиль, Стиль и Бизнес
      </div>
    </div>
  </div>

  <div class="company-history deliclub-info-wrap">
    <div class="col50">
      <div class="infoblock-black-opacity-l">
        <div>
          <div class="infoblock-header-c">Уровни Деликлуба</div>
          <div class="infoblock-text-c mint">
            <div class="level">
              <span style="color: #28e1b9">Мятный.</span> 50 и более акций
              Делимобиля
            </div>
            - 5% кешбэк бонусами за все поездки
            <br />- 5 дополнительных минут бесплатного бронирования <br />-
            доступ к машинам класса Бизнес <br />- особые условия проведения
            акций
          </div>
          <div class="infoblock-text-c silver">
            <div class="level">
              <span>Серебрянный.</span> 150 и более акций Делимобиля
            </div>
            - 10% кешбэк бонусами за все поездки
            <br />- 10 дополнительных минут бесплатного бронирования <br />-
            доступ к машинам класса Стиль и Бизнес <br />- особые условия
            проведения акций <br />- поездки в аэропорт без доплаты
          </div>
          <div class="infoblock-text-c gold">
            <div class="level">
              <span style="color: #ffd700">Золотой.</span> 400 и более акций
              Делимобиля
            </div>
            - 15% кешбэк бонусами за все поездки
            <br />- 15 дополнительных минут бесплатного бронирования <br />-
            доступ к машинам класса Суперстиль, Стиль и Бизнес <br />- особые
            условия проведения акций <br />- поездки в аэропорт без доплаты
            <br />- приоритет в поддержке
          </div>
        </div>
      </div>
      <div class="trust-bg-l">
        <div class="company-header company-header-hide">
          <div class="double-border center-block">Что даёт Деликлуб?</div>
        </div>
      </div>
    </div>
    <div class="col50">
      <div
        id="col-test-brokerage"
        class="wow up-anim"
        data-wow-offset="200"
        style="animation-name: slowmove-up; visibility: visible"
      >
        <div>
          Как стать участником<br />Деликлуба?
          <div>
            <div id="risk-q">
              <div class="number-wrap">
                <div class="number-item">1</div>
                Откройте брокерский счет в General Invest или используйте
                текущий, если вы уже являетесь клиентом компании
              </div>
              <div class="number-wrap">
                <div class="number-item">2</div>
                Приобретите не менее 50 акций Делимобиля
              </div>
              <div class="number-wrap">
                <div class="number-item">3</div>
                Получите промокод
              </div>
              <div class="number-wrap">
                <div class="number-item">4</div>
                Активируйте его в приложении Делимобиль в разделе «Бонусы и
                промокоды» или при регистрации
              </div>
              <div class="number-wrap">
                <div class="number-item">5</div>
                Пользуйтесь преимуществами, которые растут вместе с уровнем
                ваших привилегий
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Получить больше информации</div>
    <div class="form-block-inline">
      <div class="feedback-comment">
        Заполните ваши данные и в ближайшее время финансовый советник General
        Invest свяжется с вами.
      </div>
      <form
        name="feedback"
        id="feedback"
        method="post"
        action="/deliclub/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Ваше имя"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>

          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
