<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Мероприятия</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/licenses/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/press.html">Пресс-центр</a>

    &nbsp;/&nbsp;Мероприятия
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div
    id="page-header-block"
    class="news-case"
    style="
      background: url(/images/page-bg-press.jpg) no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>
  <div class="news-list">
    <div class="overflow-block">
      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.0s"
          data-wow-offset="100"
          style="
            animation-delay: 0s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/365.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/2024-05-29-00.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">29.05.2024</div>
            <a href="/events/365.html"
              >28 мая компания General Invest собрала инвесторов Татарстана в
              КазанПалас
            </a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.1s"
          data-wow-offset="100"
          style="
            animation-delay: 0.1s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/364.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/DSC08291.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">22.05.2024</div>
            <a href="/events/364.html"
              >Топ-менеджмент General Invest встретился с краснодарскими
              инвесторами</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.2s"
          data-wow-offset="100"
          style="
            animation-delay: 0.2s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/361.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/ARPH0172.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">16.04.2024</div>
            <a href="/events/361.html"
              >General Invest собрала крупных инвесторов Поволжья</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.3s"
          data-wow-offset="100"
          style="
            animation-delay: 0.3s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/360.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/SAN00203.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">11.04.2024</div>
            <a href="/events/360.html"
              >Меружан Дадалян выступил на XIII Russian Private Wealth Forum</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.4s"
          data-wow-offset="100"
          style="
            animation-delay: 0.4s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/359.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/webinar-rukovoditelei-moskovskoi-birzhi-02.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">06.03.2024</div>
            <a href="/events/359.html"
              >General Invest пригласила на свой вебинар руководителей
              Московской биржи</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.5s"
          data-wow-offset="100"
          style="
            animation-delay: 0.5s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/351.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/2024-02-26-000.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">15.02.2024</div>
            <a href="/events/351.html"
              >General Invest пригласил на бизнес-встречу представителей
              Итало-Российской Торговой Палаты</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.6s"
          data-wow-offset="100"
          style="
            animation-delay: 0.6s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/346.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/2601-delimobil.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">26.01.2024</div>
            <a href="/events/346.html"
              >Компания General Invest провела эксклюзивный вебинар с
              топ-менеджментом «Делимобиля»</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.7s"
          data-wow-offset="100"
          style="
            animation-delay: 0.7s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/334.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/events/2023-11-29-133.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">30.11.2023</div>
            <a href="/events/334.html"
              >В Москве прошел бизнес-вечер с топ-менеджерами General Invest</a
            >
          </div>
        </div>
      </div>
    </div>
    <div id="filter-pag">
      ﻿
      <div class="nav">
        <strong>1</strong>

        <a page="2" href="/events/page-2.html">2</a>

        <a page="3" href="/events/page-3.html">3</a>

        ...

        <a page="6" href="/events/page-6.html">6</a>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
