<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Новости</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/licenses/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/press.html">Пресс-центр</a>

    &nbsp;/&nbsp;Новости
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div
    id="page-header-block"
    class="news-case"
    style="
      background: url(/images/page-bg-press.jpg) no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <script>
    window.onload = function () {
      $(".single-item").slick({
        dots: false,
        prevArrow: "<div class='center-prev'></div>",
        nextArrow: "<div class='center-next'></div>",
        speed: 1000,
        fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
      });
    };
  </script>
  <div class="mainnews-list">
    <div class="single-item slick-initialized slick-slider">
      <div class="center-prev slick-arrow" style="display: block"></div>

      <div aria-live="polite" class="slick-list draggable">
        <div
          class="slick-track"
          role="listbox"
          style="opacity: 1; width: 3684px"
        >
          <div
            class="company-mainnews slick-slide"
            data-slick-index="0"
            aria-hidden="true"
            tabindex="-1"
            role="option"
            aria-describedby="slick-slide00"
            style="
              transition: opacity 1000ms ease 0s;
              width: 1228px;
              position: relative;
              left: 0px;
              top: 0px;
              z-index: 998;
              opacity: 0;
            "
          >
            <div
              class="mainnews-bg wow news-bg-anim"
              style="
                background: url('/images/news/full/231412152019/5J9A5829.jpg')
                  50% 50% / 130% no-repeat;
                animation-name: move-bg;
                visibility: visible;
              "
            >
              <div
                class="company-mainnews-header wow company-anim"
                data-wow-offset="100"
                style="animation-name: visible-scale; visibility: visible"
              >
                <div class="double-border-mainnews">
                  General Invest — лауреат премии WEALTH Navigator Awards 2023
                  <div>
                    <a
                      href="/news/337.html"
                      class="company-news-more"
                      tabindex="-1"
                      >Узнайте больше</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="company-mainnews slick-slide"
            data-slick-index="1"
            aria-hidden="true"
            tabindex="-1"
            role="option"
            aria-describedby="slick-slide01"
            style="
              transition: opacity 1000ms ease 0s;
              width: 1228px;
              position: relative;
              left: -1228px;
              top: 0px;
              z-index: 998;
              opacity: 0;
            "
          >
            <div
              class="mainnews-bg wow news-bg-anim"
              style="
                background: url('/images/news/full/211212410713/53a985cf78d5.jpg')
                  50% 50% / 130% no-repeat;
                animation-name: move-bg;
                visibility: visible;
              "
            >
              <div
                class="company-mainnews-header wow company-anim"
                data-wow-offset="100"
                style="animation-name: visible-scale; visibility: visible"
              >
                <div class="double-border-mainnews">
                  General Invest названа лучшей инвестиционной компанией года на
                  премии WEALTH Navigator Awards 2022
                  <div>
                    <a
                      href="/news/297.html"
                      class="company-news-more"
                      tabindex="-1"
                      >Узнайте больше</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="company-mainnews slick-slide slick-current slick-active"
            data-slick-index="2"
            aria-hidden="false"
            tabindex="-1"
            role="option"
            aria-describedby="slick-slide02"
            style="
              width: 1228px;
              position: relative;
              left: -2456px;
              top: 0px;
              z-index: 999;
              opacity: 1;
            "
          >
            <div
              class="mainnews-bg wow news-bg-anim"
              style="
                background: url('../../assets/press/moscow_1405.jpg') 50% 50% /
                  130% no-repeat;
                animation-name: move-bg;
                visibility: visible;
              "
            >
              <div
                class="company-mainnews-header wow company-anim"
                data-wow-offset="100"
                style="animation-name: visible-scale; visibility: visible"
              >
                <div class="double-border-mainnews">
                  General Invest вошла в список крупнейших брокерских компаний
                  России по версии КоммерсантЪ
                  <div>
                    <a
                      href="/news/250.html"
                      class="company-news-more"
                      tabindex="0"
                      >Узнайте больше</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center-next slick-arrow" style="display: block"></div>
    </div>
  </div>

  <div class="news-list">
    <div class="overflow-block">
      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.0s"
          data-wow-offset="100"
          style="
            animation-delay: 0s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/369.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/17.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">06.06.2024</div>
            <a href="/news/369.html">Важные изменения по остаткам USD</a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.1s"
          data-wow-offset="100"
          style="
            animation-delay: 0.1s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/353.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/AdobeStock_87281901.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">01.06.2024</div>
            <a href="/news/353.html">General Invest напоминает о мошенниках</a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.2s"
          data-wow-offset="100"
          style="
            animation-delay: 0.2s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/368.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/7.jpg') center center
                no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">31.05.2024</div>
            <a href="/news/368.html"
              >Внесение изменений во внутренние документы ООО Концерн
              «ДЖЕНЕРАЛ-ИНВЕСТ»</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.3s"
          data-wow-offset="100"
          style="
            animation-delay: 0.3s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/367.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/ATL45720.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">31.05.2024</div>
            <a href="/news/367.html">Хранение USD на брокерских счетах</a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.4s"
          data-wow-offset="100"
          style="
            animation-delay: 0.4s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/366.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/RlbGFub3VuYXMucnUvdXBsb2Fkcy.jpeg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">30.05.2024</div>
            <a href="/news/366.html"
              >General Invest предлагает участие в IPO Iva Technologies на
              ММВБ</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.5s"
          data-wow-offset="100"
          style="
            animation-delay: 0.5s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/363.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/monument-statue-plaza-landmark-cathedral-sculpture-ancient-history-74112.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">23.04.2024</div>
            <a href="/news/363.html"
              >Внесение изменений во внутренние документы ООО Концерн
              «ДЖЕНЕРАЛ-ИНВЕСТ»</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.6s"
          data-wow-offset="100"
          style="
            animation-delay: 0.6s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/362.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/mts-bank.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">19.04.2024</div>
            <a href="/news/362.html"
              >General Invest предлагает участие в IPO МТС Банк на ММВБ</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over news-item">
        <div
          class="wow company-anim"
          data-wow-delay="0.7s"
          data-wow-offset="100"
          style="
            animation-delay: 0.7s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/358.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../../assets/press/1529694443_171_0_2902_2048.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">01.04.2024</div>
            <a href="/news/358.html"
              >Новости Московской биржи: расширение списка инструментов для
              отдельных режимов торгов</a
            >
          </div>
        </div>
      </div>
    </div>
    <div id="filter-pag">
      ﻿
      <div class="nav">
        <strong>1</strong>

        <a page="2" href="/news/page-2.html">2</a>

        <a page="3" href="/news/page-3.html">3</a>

        ...

        <a page="23" href="/news/page-23.html">23</a>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
