<div id="column-slide" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="slogan">
    нвестиции<br />как искусство
    <div class="slogan-small-comment">
      ТОП-10 лучших управляющих благосостоянием<br />по версии Forbes
    </div>
  </div>
  <div id="content-slide-block"></div>
  <div id="about-slide-m"></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;О компании
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  ﻿
  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">Наши преимущества</div>
    <div class="col25">
      <div
        class="advantage-block wow anim-visible advantage-hidden"
        data-wow-delay="0.3s"
        style="
          animation-delay: 0.3s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div>1.</div>
        Компания с историей
      </div>
    </div>
    <div class="col25">
      <div
        class="advantage-block wow anim-visible advantage-hidden"
        data-wow-delay="0.6s"
        style="
          animation-delay: 0.6s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div>2.</div>
        Западные традиции
      </div>
    </div>
    <div class="col25">
      <div
        class="advantage-block wow anim-visible advantage-hidden"
        data-wow-delay="0.9s"
        style="
          animation-delay: 0.9s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div>3.</div>
        100% иностранный капитал
      </div>
    </div>
    <div class="col25">
      <div
        class="advantage-block wow anim-visible advantage-hidden"
        data-wow-delay="1.2s"
        style="
          animation-delay: 1.2s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div>4.</div>
        Полный спектр услуг
      </div>
    </div>
  </div>

  <div id="about-ceo">
    <img
      src="../../assets/team/about-khenkin.png"
      id="about-ceo-photo"
      class="wow up-anim"
      data-wow-offset="300"
      alt=""
      style="animation-name: slowmove-up; visibility: visible"
    />
    <div
      id="about-ceo-info"
      class="wow anim-visible"
      data-wow-delay="0.4s"
      data-wow-offset="300"
      style="
        animation-delay: 0.4s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div id="about-ceo-name">Александр Хенкин</div>
      <div id="about-ceo-position">Генеральный директор</div>
      <p>
        Александр обладает многолетним опытом работы в банковской сфере и
        индустрии управления частным капиталом.
      </p>
      <p>В банке «Открытие» он возглавлял московскую региональную дирекцию.</p>
      <p>
        В 2018 году был назначен директором департамента по работе с
        корпоративными клиентами БКС Банка.
      </p>
      <p>
        Затем присоединился к команде А-Клуб (Альфа-Банк) в должности
        дивизионного руководителя.
      </p>
      <p>С августа 2021 года — возглавлял «Зенит» Private banking.</p>
      <p>
        К команде General Invest присоединился в марте 2023 года и занял пост
        члена Совета директоров и генерального директора компании.
      </p>
      <p>Окончил Брянский государственный технический университет.</p>
      <div class="test-button-over ceo-button">
        <a href="/team.html" class="test-button"
          ><span>Команда General Invest</span></a
        >
      </div>
    </div>
  </div>

  <div class="about-points overflow-block">
    <div class="col30 point-block-over">
      <div
        class="point-block point01 wow company-anim"
        data-wow-delay="0.4s"
        data-wow-offset="200"
        style="
          animation-delay: 0.4s;
          animation-name: visible-scale;
          visibility: visible;
        "
      >
        <div class="point-opacity"></div>
        <div class="point-header">Наша миссия</div>
        <div
          class="point-comment wow animated"
          data-wow-delay="0.6s"
          style="animation-delay: 0.6s; visibility: visible"
        >
          Содействовать развитию инвестирования в России и создавать
          благоприятные условия для привлечения иностранных инвестиций в
          российскую экономику.
        </div>
      </div>
    </div>
    <div class="col30 point-block-over">
      <div
        class="point-block point02 wow company-anim"
        data-wow-delay="0.8s"
        data-wow-offset="200"
        style="
          animation-delay: 0.8s;
          animation-name: visible-scale;
          visibility: visible;
        "
      >
        <div class="point-opacity"></div>
        <div class="point-header">Наша цель</div>
        <div
          class="point-comment wow animated"
          data-wow-delay="0.6s"
          style="animation-delay: 0.6s; visibility: visible"
        >
          Увеличивать благосостояние российских и зарубежных инвесторов,
          применяя европейскую практику.
        </div>
      </div>
    </div>
    <div class="col30 point-block-over">
      <div
        class="point-block point03 wow company-anim"
        data-wow-delay="1.2s"
        data-wow-offset="200"
        style="
          animation-delay: 1.2s;
          animation-name: visible-scale;
          visibility: visible;
        "
      >
        <div class="point-opacity"></div>
        <div class="point-header">Наш подход</div>
        <div
          class="point-comment wow animated"
          data-wow-delay="0.6s"
          style="animation-delay: 0.6s; visibility: visible"
        >
          Европейские традиции в управлении активами помогают нам разрабатывать
          инвестиционные продукты с оптимальной доходностью.
        </div>
      </div>
    </div>
  </div>

  <script>
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1200,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="company-history-awards">
    <div class="awards-header">Достижения</div>
    <div class="awards-string-block">
      <div style="width: 100%; height: 250px">
        <div class="multiple-item slick-initialized slick-slider">
          <div class="slick-prev slick-arrow" style="display: block"></div>
          <div aria-live="polite" class="slick-list draggable">
            <div
              class="slick-track"
              role="listbox"
              style="opacity: 1; width: 4403px; left: -2072px"
            >
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w4.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="-5"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >3-е место в 2019 г., 4-е в 2018 г., 6-е в 2017 г., среди
                  инвестиционных компаний России</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w2.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="-4"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >Лауреат премии «Лучшая инвестиционная компания», 2016, 2018,
                  2021. Генеральный директор лауреат премии «Инвестиционный
                  консультант года», 2021</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w1.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="-3"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >Компания года в сфере Wealth Management, премия «Финансовая
                  элита России», 2016 год</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w3.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="-2"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >Топ-10 лучших управляющих благосостоянием по версии Forbes,
                  2015 год</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w5.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="-1"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >Лауреат премии в номинации «За привлечение иностранных
                  инвестиций в Россию», 2011 год</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w6.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="0"
                aria-hidden="true"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide00"
              >
                <span
                  >General Invest вошла в список крупнейших брокерских компаний
                  России по версии КоммерсантЪ, 2022, 2021, 2023 годы</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w8.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="1"
                aria-hidden="true"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide01"
              >
                <span>«Лучшая инвестиционная Компания года», 2022</span>
              </div>
              <div
                class="awards-single wow up-anim slick-slide"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w4.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="2"
                aria-hidden="true"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide02"
              >
                <span
                  >3-е место в 2019 г., 4-е в 2018 г., 6-е в 2017 г., среди
                  инвестиционных компаний России</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-current slick-active"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w2.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="3"
                aria-hidden="false"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide03"
              >
                <span
                  >Лауреат премии «Лучшая инвестиционная компания», 2016, 2018,
                  2021. Генеральный директор лауреат премии «Инвестиционный
                  консультант года», 2021</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-active"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w1.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="4"
                aria-hidden="false"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide04"
              >
                <span
                  >Компания года в сфере Wealth Management, премия «Финансовая
                  элита России», 2016 год</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-active"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w3.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="5"
                aria-hidden="false"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide05"
              >
                <span
                  >Топ-10 лучших управляющих благосостоянием по версии Forbes,
                  2015 год</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-active"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w5.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  animation-name: slowmove-up;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="6"
                aria-hidden="false"
                tabindex="-1"
                role="option"
                aria-describedby="slick-slide06"
              >
                <span
                  >Лауреат премии в номинации «За привлечение иностранных
                  инвестиций в Россию», 2011 год</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned slick-active"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w6.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="7"
                aria-hidden="false"
                tabindex="-1"
              >
                <span
                  >General Invest вошла в список крупнейших брокерских компаний
                  России по версии КоммерсантЪ, 2022, 2021, 2023 годы</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w8.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="8"
                aria-hidden="true"
                tabindex="-1"
              >
                <span>«Лучшая инвестиционная Компания года», 2022</span>
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w4.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="9"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >3-е место в 2019 г., 4-е в 2018 г., 6-е в 2017 г., среди
                  инвестиционных компаний России</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w2.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="10"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >Лауреат премии «Лучшая инвестиционная компания», 2016, 2018,
                  2021. Генеральный директор лауреат премии «Инвестиционный
                  консультант года», 2021</span
                >
              </div>
              <div
                class="awards-single wow up-anim slick-slide slick-cloned"
                data-wow-offset="100"
                data-wow-delay="0.3s"
                style="
                  background: url('../../assets/awards/logo_awards_w1.png') left
                    top no-repeat;
                  animation-delay: 0.3s;
                  visibility: visible;
                  width: 219px;
                "
                data-slick-index="11"
                aria-hidden="true"
                tabindex="-1"
              >
                <span
                  >Компания года в сфере Wealth Management, премия «Финансовая
                  элита России», 2016 год</span
                >
              </div>
            </div>
          </div>

          <div class="slick-next slick-arrow" style="display: block"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <div
        class="history-bg wow history-bg-anim"
        style="animation-name: move-bg-f; visibility: visible"
      >
        <div
          class="about-header wow company-anim"
          data-wow-offset="400"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="double-border-news">
            Наша история
            <div class="history-block-comment">
              Компания General Invest создана в 2009 году и с тех пор оказывает
              услуги по управлению активами крупных частных инвесторов, занимая
              прочные позиции в российских и международных рейтингах.
            </div>
            <div>
              <a href="/history.html" class="history-more">Лента времени</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Раскрытие информации
            <div>
              <a href="/information_disclosure.html" class="company-anot-more"
                >Узнайте больше</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Лицензии
            <div>
              <a href="/licenses.html" class="company-anot-more"
                >Узнайте больше</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Получить больше информации</div>
    <div class="form-block-inline">
      <div class="feedback-comment">
        Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
      </div>
      <form
        name="feedback"
        id="feedback"
        method="post"
        action="/feedback/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Ваше имя"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>

          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
