import { Component } from '@angular/core';

@Component({
  selector: 'app-how-to-become-a-client',
  templateUrl: './how-to-become-a-client.component.html',
  styleUrls: ['./how-to-become-a-client.component.scss']
})
export class HowToBecomeAClientComponent {

}
