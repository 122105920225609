<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Как стать клиентом</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/how-to-become/page-bg-client.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about.html">О компании</a>

    &nbsp;/&nbsp;Как стать клиентом
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="company-history">
    <div class="col50">
      <div class="hclient-bg">
        <div
          class="hclient-block wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="hclient-block-over">
            <div class="hclient-header">
              Квалифицированным инвесторам мы предлагаем лучшие инвестиционные
              идеи
            </div>
            Квалифицированные инвесторы – отдельная категория инвесторов,
            обладающая необходимыми знаниями, финансовыми возможностями и опытом
            работы на рынке ценных бумаг, который позволяет им качественно
            оценивать риски и в достаточной степени осознанно инвестировать в
            более рискованные инструменты финансового рынка.
          </div>
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Квалифицированным инвесторам
            <div>
              <div class="company-anot-more advantage-click">
                Узнайте больше
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Неквалифицированным инвесторам
            <div>
              <div class="company-anot-more specific-click">Узнайте больше</div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoblock-black advantage-infoblock">
        <div class="infoblock-header-hc">Квалифицированным инвесторам</div>
        <div class="hclient-text">
          Если вы квалифицированный инвестор, мы можем начать работу в любом
          удобном формате.
        </div>
        <div class="infoblock-transp"></div>
      </div>
      <div class="infoblock-black specific-infoblock">
        <div class="infoblock-header-hc">Неквалифицированным инвесторам</div>
        <div class="hclient-text">
          Если у вас есть все необходимое для получения статуса
          квалифицированного инвестора, вы можете подать заявление, и мы в
          кратчайшие сроки оформим все документы.
        </div>
        <div class="infoblock-transp"></div>
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="100">Станьте клиентом</div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>1.</div>
        Встреча с управляющим
      </div>
      <div class="advantage-info">
        Свяжитесь с нами любым удобным для вас способом:<br /><br />— +7 (495)
        727-00-77;<br />— info@generalinvest.ru;<br />— с помощью формы обратной
        связи.<br /><br /><a href="#dialog" class="button-link popup-form"
          >Заказать звонок</a
        >
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>2.</div>
        Выбор формата инвестирования
      </div>
      <div class="advantage-info">
        На встрече мы подберем комфортный для вас формат работы и инвестиционные
        продукты, отвечающие вашим финансовым задачам.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>3.</div>
        Оформление документов
      </div>
      <div class="advantage-info">
        Мы оформим все необходимые документы и возьмем на себя формальные
        процедуры.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>4.</div>
        Инвестиционный процесс
      </div>
      <div class="advantage-info">
        Мы регулярно присылаем подробные отчеты обо всех операциях по счету,
        доходности и наполнению портфеля — вы легко можете контролировать
        инвестиционный процесс и вносить в него коррективы.
      </div>
    </div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Получить больше информации</div>
    <div class="form-block-inline">
      <div class="feedback-comment">
        Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
      </div>
      <form
        name="feedback"
        id="feedback"
        method="post"
        action="/feedback/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Ваше имя"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>
          
          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
