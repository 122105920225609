import { Component } from '@angular/core';

@Component({
  selector: 'app-affiliate-programs',
  templateUrl: './affiliate-programs.component.html',
  styleUrls: ['./affiliate-programs.component.scss']
})
export class AffiliateProgramsComponent {

}
