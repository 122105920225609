<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Пресс-центр</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../assets/social_responsibility/page-bg-press\ \(1\).jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Пресс-центр
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="single-header">
    <div class="strategy-header nomargin">Новости</div>
  </div>

  <script>
    window.onload = function () {
      $(".single-item").slick({
        dots: false,
        prevArrow: '<div class="top-prev"></div>',
        nextArrow: '<div class="top-next"></div>',
        speed: 1000,
        fade: true,
        autoplay: true,
        autoplaySpeed: 6000,
      });

      $(".multiple-item").slick({
        autoplay: false,
        autoplaySpeed: 6000,
        speed: 1200,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 2,
              speed: 1200,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              fade: true,
              slidesToShow: 1,
              speed: 600,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="single-item slick-initialized slick-slider">
    <div class="top-prev slick-arrow" style="display: block"></div>

    <div aria-live="polite" class="slick-list draggable">
      <div class="slick-track" style="opacity: 1; width: 4719px" role="listbox">
        <div
          class="company-news slick-slide"
          data-slick-index="0"
          aria-hidden="true"
          style="
            transition: opacity 1000ms ease 0s;
            width: 1573px;
            position: relative;
            left: 0px;
            top: 0px;
            z-index: 998;
            opacity: 0;
          "
          tabindex="-1"
          role="option"
          aria-describedby="slick-slide00"
        >
          <div
            class="news-bg wow news-bg-anim"
            data-wow-offset="400"
            style="
              background: url('../../assets/press/5J9A5829.jpg') 30%
                50% / 130% no-repeat;
              animation-name: move-bg;
              visibility: visible;
            "
          >
            <div
              class="company-news-header wow company-anim"
              data-wow-offset="100"
              style="animation-name: visible-scale; visibility: visible"
            >
              <div class="double-border-news">
                <span
                  >General Invest — лауреат премии WEALTH Navigator Awards
                  2023</span
                >
                <div>
                  <a
                    href="/news/337.html"
                    class="company-news-more"
                    tabindex="-1"
                    >Узнайте больше</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="company-news slick-slide"
          data-slick-index="1"
          aria-hidden="true"
          style="
            width: 1573px;
            position: relative;
            left: -1573px;
            top: 0px;
            transition: opacity 1000ms ease 0s;
            opacity: 0;
            z-index: 998;
          "
          tabindex="-1"
          role="option"
          aria-describedby="slick-slide01"
        >
          <div
            class="news-bg wow news-bg-anim"
            data-wow-offset="400"
            style="
              background: url('../../assets/press/53a985cf78d5.jpg')
                30% 50% / 130% no-repeat;
              animation-name: move-bg;
              visibility: visible;
            "
          >
            <div
              class="company-news-header wow company-anim"
              data-wow-offset="100"
              style="animation-name: visible-scale; visibility: visible"
            >
              <div class="double-border-news">
                <span
                  >General Invest названа лучшей инвестиционной компанией года
                  на премии WEALTH Navigator Awards 2022</span
                >
                <div>
                  <a
                    href="/news/297.html"
                    class="company-news-more"
                    tabindex="0"
                    >Узнайте больше</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="company-news slick-slide slick-current slick-active"
          data-slick-index="2"
          aria-hidden="false"
          style="
            width: 1573px;
            position: relative;
            left: -3146px;
            top: 0px;
            transition: opacity 1000ms ease 0s;
            opacity: 1;
            z-index: 1000;
          "
          tabindex="-1"
          role="option"
          aria-describedby="slick-slide02"
        >
          <div
            class="news-bg wow news-bg-anim"
            data-wow-offset="400"
            style="
              background: url('../../assets/press/moscow_1405.jpg')
                30% 50% / 130% no-repeat;
              animation-name: move-bg;
              visibility: visible;
            "
          >
            <div
              class="company-news-header wow company-anim"
              data-wow-offset="100"
              style="animation-name: visible-scale; visibility: visible"
            >
              <div class="double-border-news">
                <span
                  >General Invest вошла в список крупнейших брокерских компаний
                  России по версии КоммерсантЪ</span
                >
                <div>
                  <a
                    href="/news/250.html"
                    class="company-news-more"
                    tabindex="-1"
                    >Узнайте больше</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="top-next slick-arrow" style="display: block"></div>
  </div>

  <div class="news-list">
    <div class="overflow-block">
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.0s"
          data-wow-offset="100"
          style="
            animation-delay: 0s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/369.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/17.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">06.06.2024</div>
            <a href="/news/369.html">Важные изменения по остаткам USD</a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.1s"
          data-wow-offset="100"
          style="
            animation-delay: 0.1s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/368.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/7.jpg') center center
                no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">31.05.2024</div>
            <a href="/news/368.html"
              >Внесение изменений во внутренние документы ООО Концерн
              «ДЖЕНЕРАЛ-ИНВЕСТ»</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.2s"
          data-wow-offset="100"
          style="
            animation-delay: 0.2s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/367.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/ATL45720.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">31.05.2024</div>
            <a href="/news/367.html">Хранение USD на брокерских счетах</a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.3s"
          data-wow-offset="100"
          style="
            animation-delay: 0.3s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/news/353.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/AdobeStock_87281901.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">31.05.2024</div>
            <a href="/news/353.html">General Invest напоминает о мошенниках</a>
          </div>
        </div>
      </div>
    </div>
    <div class="allnews">
      <a href="/news.html" class="button-link">Все новости</a>
    </div>
  </div>

  <div class="strategy-block">
    <div class="strategy-header">Публикации в СМИ</div>
    <div class="overflow-block">
      <div
        class="col25 wow anim-visible advantage-hidden"
        data-wow-delay="0.0s"
        data-wow-offset="200"
        style="
          animation-delay: 0s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="press-list-block">
          <a
            href="/rus/press_news/876.html"
            style="
              background: url('../../assets/press/tatcenter.png') left bottom
                no-repeat;
            "
          ></a>
        </div>
        <div class="press-list-header">
          <div class="date-news-list">06.06.2024</div>
          <a href="/rus/press_news/876.html"
            >Облигации, золото и IPO — ключевые тренды для инвесторов
            Татарстана</a
          >
        </div>
      </div>

      <div
        class="col25 wow anim-visible advantage-hidden"
        data-wow-delay="0.1s"
        data-wow-offset="200"
        style="
          animation-delay: 0.1s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="press-list-block">
          <a
            href="/rus/press_news/875.html"
            style="
              background: url('../../assets/press/logo-gl.png') left bottom
                no-repeat;
            "
          ></a>
        </div>
        <div class="press-list-header">
          <div class="date-news-list">04.06.2024</div>
          <a href="/rus/press_news/875.html"
            >General Invest: инвестиции в настоящее и будущее</a
          >
        </div>
      </div>

      <div
        class="col25 wow anim-visible advantage-hidden"
        data-wow-delay="0.2s"
        data-wow-offset="200"
        style="
          animation-delay: 0.2s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="press-list-block">
          <a
            href="/rus/press_news/874.html"
            style="
              background: url('../../assets/press/rbk.png') left bottom no-repeat;
            "
          ></a>
        </div>
        <div class="press-list-header">
          <div class="date-news-list">28.05.2024</div>
          <a href="/rus/press_news/874.html">Ставка на Китай</a>
        </div>
      </div>

      <div
        class="col25 wow anim-visible advantage-hidden"
        data-wow-delay="0.3s"
        data-wow-offset="200"
        style="
          animation-delay: 0.3s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="press-list-block">
          <a
            href="/rus/press_news/873.html"
            style="
              background: url(../../assets/press/rbk.png) left bottom no-repeat;
            "
          ></a>
        </div>
        <div class="press-list-header">
          <div class="date-news-list">23.05.2024</div>
          <a href="/rus/press_news/873.html">Экономика США</a>
        </div>
      </div>
    </div>
    <div class="allnews">
      <div>
        <a href="/press_news.html" class="button-link">Все публикации</a>
      </div>
    </div>
  </div>

  <div class="news-list">
    <div class="strategy-header">Мероприятия</div>
    <div class="overflow-block">
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.0s"
          data-wow-offset="100"
          style="
            animation-delay: 0s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/365.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/2024-05-29-00.jpg')
                center center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">29.05.2024</div>
            <a href="/events/365.html"
              >28 мая компания General Invest собрала инвесторов Татарстана в
              КазанПалас
            </a>
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.1s"
          data-wow-offset="100"
          style="
            animation-delay: 0.1s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/364.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/DSC08291.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">22.05.2024</div>
            <a href="/events/364.html"
              >Топ-менеджмент General Invest встретился с краснодарскими
              инвесторами</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.2s"
          data-wow-offset="100"
          style="
            animation-delay: 0.2s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/361.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/ARPH0172.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">16.04.2024</div>
            <a href="/events/361.html"
              >General Invest собрала крупных инвесторов Поволжья</a
            >
          </div>
        </div>
      </div>

      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.3s"
          data-wow-offset="100"
          style="
            animation-delay: 0.3s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <a
            href="/events/360.html"
            class="strategy-item news-list-pic"
            style="
              background: url('../../assets/press/ARPH0172.jpg') center
                center no-repeat;
              background-size: cover;
            "
          ></a>
          <div class="strategy-info">
            <div class="date-news-list">11.04.2024</div>
            <a href="/events/360.html"
              >Меружан Дадалян выступил на XIII Russian Private Wealth Forum</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="allnews">
      <a href="/events.html" class="button-link">Все мероприятия</a>
    </div>
  </div>

  <div class="company-history-awards">
    <div class="col30 press-pr-info">
      <div>Контакты для СМИ</div>
      Уважаемые коллеги! Инвестиционная компания General Invest работает на
      российском и международном финансовых рынках.
      <br /><br />Наши спикеры с удовольствием поделятся с вами своими знаниями.
      Они помогут в подготовке материалов на финансовую тематику и
      прокомментируют текущую экономическую ситуацию.
    </div>

    <div class="col60 press-pr-block">
      <div class="pr-photo-block">
        <div class="team-item-photo">
          <img src="/images/team/y-griberman.png" alt="" />
        </div>
      </div>
      <div class="pr-item-info">
        <div class="team-name">Юлия Гриберман</div>
        <div class="team-position">
          Директор по связям с общественностью и маркетингу
        </div>
        +7 (495) 727-00-77 (доб. 238)
        <br />+7 (909)64-64-272 <br /><br /><a
          href="mailto:jgriberman@generalinvest.ru"
          >jgriberman@generalinvest.ru</a
        >
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
