<div id="column-slide-second" class="midle-height visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Как стать партнером</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/affiliate_programs/affiliate_programs.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
      height: 420px;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about.html">О компании</a>

    &nbsp;/&nbsp;Как стать партнером
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="quote-block">
    <div class="b-button-wrap">
      <div class="b-button">
        <a href="#partner-dialog" class="transp-button popup-form"
          ><span>Стать Партнером</span></a
        >
      </div>
    </div>
    <div class="quote-flex">
      <div
        id="quote-text-comment"
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        Партнерская программа – это возможность сотрудничества с компанией
        General Invest, как для опытных инвестиционных менеджеров, так и
        профессиональных участников рынка.
        <br /><br />Мы знаем об инвестициях все и предлагаем нашим партнерам
        доступ к широкому спектру инвестиционных услуг, развитой операционной
        платформе и инфраструктуре компании, высококлассной экспертизе команды,
        а также поддержку и сопровождение на всех этапах сотрудничества.
      </div>
      <div
        id="quote-photo"
        style="
          background: url('../../../assets/affiliate_programs/beyret-text.jpg') center center
            no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        class="wow up-anim"
        data-wow-offset="300"
      ></div>
      <div
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div id="quote-text">
          Мы исходим из позиции win-win. Акцент на быстрое решение совместных
          задач согласно запросам клиентов, гибкость формата взаимодействия,
          помощь в закрытии сделок и поисках новых возможностей для совместного
          продуктивного бизнеса.
        </div>
        <div id="quote-name">
          <div>Ирина Бейрет</div>
          Заместитель генерального директора по развитию партнерской сети
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50 first-anchor">
      <div class="brokerage-bg"></div>
    </div>
    <div class="col50 adaptiv-step">
      <div
        id="col-test-brokerage"
        class="wow up-anim red"
        data-wow-offset="200"
        style="animation-name: slowmove-up; visibility: visible"
      >
        Как стать<br />Партнером?
        <div>
          <div id="risk-q">Открыть бизнес-партнерство с General Invest</div>
        </div>
        <div class="test-button-over">
          <a href="#partner-dialog" class="test-button red popup-form"
            ><span>Направить заявку</span></a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="about-advantage second-anchor">
    <div class="strategy-header" data-wow-offset="200">
      Преимущества партнерской программы General&nbsp;Invest для вас:
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>1.</div>
        Доверие и прозрачность
      </div>
      <div class="advantage-info">
        Возможность строить свой бизнес на основе доверия и долгосрочного
        сотрудничества. Честность, прозрачность и доверие во всех аспектах
        взаимодействия между компанией, партнером и клиентом.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>2.</div>
        Фокус на клиента
      </div>
      <div class="advantage-info">
        Возможность сфокусироваться на общении с клиентом, оставив решение его
        вопросов и задач профессиональной команде экспертов General Invest,
        которая предоставляет доступ к своим ресурсам, платформе и аналитике.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>3.</div>
        Персональный менеджер
      </div>
      <div class="advantage-info">
        Работа построена по принципу «одного окна», где Партнер получает
        профессиональное консультирование, скорость ответа и индивидуальный
        подход к решению задач любого уровня через своего персонального
        менеджера.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>4.</div>
        Высокое вознаграждение
      </div>
      <div class="advantage-info">
        Доступ к одной из лучших на рынке компенсационных политик, гарантия
        высокого и прозрачного вознаграждения от всех комиссий, сформированных в
        рамках платформы General Invest
      </div>
    </div>
  </div>

  <script>
    $(document).ready(function () {
      recount_form();
    });
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: false,
        autoplaySpeed: 6000,
        speed: 1200,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1550,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="company-history">
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Финансовые советники
            <div>
              <div class="company-anot-more advantage-click">
                Узнайте больше
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Профессиональные участники
            <div>
              <div class="company-anot-more specific-click">Узнайте больше</div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoblock-black advantage-infoblock">
        <div class="infoblock-header">Финансовые советники</div>
        <div class="infoblock-item smallitem">
          <strong>Топ-сейлзы</strong> с большим опытом работы, где отношения с
          клиентом выстроены на доверии и успешном трек-рекорде.
        </div>
        <div class="infoblock-item smallitem">
          <strong>Управляющие активами</strong> частных состоятельных клиентов и
          юридических компаний. Профессионалы ведущие портфели клиентов на
          протяжении многих лет
        </div>
        <div class="infoblock-item smallitem">
          <strong>Multi-family office</strong>. Команда профессиональных людей,
          решающих финансовые задачи клиента по принципу 360*
        </div>
        <div class="infoblock-transp"></div>
      </div>
      <div class="infoblock-black specific-infoblock">
        <div class="infoblock-header">Профессиональные участники</div>
        <div class="infoblock-item smallitem">
          <strong>Независимый финансовый советник</strong>. Физические или
          юридические лица - профессионалы на рынке консалтинговых услуг в
          области финансов и личного финансового планирования, включенные в
          единый реестр инвестиционных советников
        </div>
        <div class="infoblock-item smallitem">
          <strong>Банки</strong>. Уровень подразделений Premium и Private
        </div>
        <div class="infoblock-transp"></div>
      </div>
    </div>
    <div class="col50">
      <div class="account-bg flex">
        <div class="company-header">
          <div class="double-border">Кто может стать нашим Партнером</div>
        </div>
      </div>
    </div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Стать партнёром</div>
    <div class="form-block-inline">
      <div class="feedback-comment form-hide-block">
        Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
      </div>
      <form
        name="feedback"
        id="affiliate"
        method="post"
        action="/affiliate/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Контактное лицо"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="city"
            name="city"
            value=""
            size="50"
            placeholder="Город"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <textarea
            id="text"
            name="text"
            cols="17"
            rows="18"
            placeholder="Комментарий"
          ></textarea>
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>
          <div class="capcha-block">
            <div class="capcha-name">
              Защитный код<br /><img
                src="/index_ajax.php?inc=capcha"
                id="capcha_img"
              />
            </div>
            <input type="text" id="capcha" name="capcha" value="" size="20" />
            <div class="form-error"></div>
          </div>
          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <div id="partner-dialog" class="zoom-anim-dialog mfp-hide agree-content">
    <div class="feedback-header-modal form-hide-block-mod">Стать партнёром</div>
    <div class="feedback-comment form-hide-block-mod">
      Заполните ваши данные, в ближайшее время мы свяжемся с вами.
    </div>
    <form
      name="partner"
      id="partner"
      method="post"
      action="/affiliate/"
      enctype="multipart/form-data"
    >
      <div class="form-block-center">
        <input
          type="text"
          id="name"
          name="name"
          value=""
          size="50"
          placeholder="Контактное лицо"
        />
        <div class="form-error"></div>
        <input
          type="text"
          id="phone"
          name="phone"
          value=""
          size="50"
          placeholder="Телефон"
        />
        <div class="form-error"></div>
        <input
          type="text"
          id="city"
          name="city"
          value=""
          size="50"
          placeholder="Город"
        />
        <div class="form-error"></div>
        <input
          type="text"
          id="email"
          name="email"
          value=""
          size="50"
          placeholder="E-mail"
        />
        <div class="form-error"></div>
        <textarea
          id="text"
          name="text"
          cols="17"
          rows="18"
          placeholder="Комментарий"
        ></textarea>
        <div class="form-error"></div>
        <div class="check-block">
          <div class="check-name">
            Даю согласие на
            <a href="/consent.html" target="_blank"
              >обработку персональных данных</a
            >
            <input type="checkbox" id="agree" name="agree" value="0" />
            <div class="form-error check-error"></div>
          </div>
        </div>
       
        <input
          type="submit"
          id="submit_form"
          name="submit_form"
          value="Отправить"
          size=""
        />
        <div class="form-error"></div>
      </div>
    </form>
    <div id="form-result"></div>
  </div>

  <script>
    $(window).on("load resize", function () {
      if ($(window).width() <= 1279) {
        $(".adaptiv-step").insertAfter(".second-anchor");
      }
      if ($(window).width() > 1279) {
        $(".adaptiv-step").insertAfter(".first-anchor");
      }
    });
  </script>

  <app-footer></app-footer>
</div>
