<div id="column-slide" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="services-header">
    скусство управления<br />частным капиталом
    <div id="page-header-comment">Брокерское обслуживание</div>
  </div>
  <div id="brokerage-slide"></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Услуги &nbsp;/&nbsp;Брокерское обслуживание
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">Принцип работы</div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>1.</div>
        Международные стандарты качества обслуживания
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>2.</div>
        Максимальная скорость совершения операций
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>3.</div>
        Работа на ведущих финансовых площадках мира
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>4.</div>
        Операции с широким спектром активов
      </div>
    </div>
  </div>

  <div class="quote-block-g">
    <div id="quote-column">
      <div
        id="quote-photo"
        style="
          background: url('/images/team/merudzan-text.jpg') center center
            no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        class="wow up-anim"
        data-wow-offset="300"
      ></div>
      <div
        id="quote-text-block"
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div id="quote-text">
          Искусство приумножения капитала — это ювелирная работа, требующая
          полной концентрации, оперативности в принятии решений, смелости и
          проактивности. Зная предпочтения каждого клиента, мы формируем
          актуальные для него идеи, которые приносят желаемый результат здесь и
          сейчас.
        </div>
        <div id="quote-name">
          <div>Меружан Дадалян</div>
          Заместитель генерального директора по управлению частным капиталом и
          инвестициями
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="strategy-header" data-wow-offset="200">Преимущества</div>
    <div class="overflow-block">
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.3s"
          data-wow-offset="200"
          style="
            animation-delay: 0.3s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage01">
            <div class="point-header">Персональный менеджер</div>
          </div>
          <div class="strategy-info">
            Постоянная информационная и техническая поддержка инвестора и
            индивидуальные рекомендации в соответствие с риск-профилем и
            предпочтениями клиента
          </div>
        </div>
      </div>
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.6s"
          data-wow-offset="200"
          style="
            animation-delay: 0.6s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage02">
            <div class="point-header">Совершение сделок по телефону</div>
          </div>
          <div class="strategy-info">
            Чтобы сделки совершались быстро, а клиентам было комфортно, мы
            принимаем голосовые заявки на торговые операции с 10:00 до 18:00 по
            московскому времени
          </div>
        </div>
      </div>
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.9s"
          data-wow-offset="200"
          style="
            animation-delay: 0.9s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage03">
            <div class="point-header">Сделки на внебиржевом рынке</div>
          </div>
          <div class="strategy-info">
            Нашим клиентам доступен широкий выбор внебиржевых инструментов
          </div>
        </div>
      </div>
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="1.2s"
          data-wow-offset="200"
          style="
            animation-delay: 1.2s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage04">
            <div class="point-header">Аналитика рынка</div>
          </div>
          <div class="strategy-info">
            Мы регулярно предоставляем нашим клиентам аналитику рынка и
            подбираем индивидуальные рекомендации для каждого инвестора
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <div class="brokerage-bg"></div>
    </div>
    <div class="col50">
      <div
        id="col-test-brokerage"
        class="wow up-anim"
        data-wow-offset="200"
        style="animation-name: slowmove-up; visibility: visible"
      >
        Какой<br />вы инвестор?
        <div><div id="risk-q">Определите свой риск-профиль</div></div>
        <div class="test-button-over">
          <a href="/investment_test.html" class="test-button"
            ><span>Перейти к анкете</span></a
          >
        </div>
      </div>
      <!--<div class="calculator-block">
    <div class="calculator"><div class="cals-border">
    <div class="cals-header">Калькулятор</div>
    <div class="cals-padding">
    <div class="cals-string" id="i_buy"><div class="cals-string-info">Я покупаю</div><input type="text" id="currency-field" name="currency" value="100000" size="50" /><div class="currency-block currency-active" type="sell">$</div><div class="currency-block" type="sell">€</div><div class="currency-block" type="sell">₽</div></div>
    <div class="cals-string"><div class="cals-string-info">По курсу</div><div class="cals-string-value" id="curs"></div></div>
    <div class="cals-string"id="i_sell"><div class="cals-string-info">Сумма сделки</div><div class="cals-string-value" id="summ"></div><div class="currency-block">$</div><div class="currency-block">€</div><div class="currency-block currency-active">₽</div></div>
    </div>
    </div></div>
    </div>-->
    </div>
  </div>

  <script>
    /*
    var aCurs=[66.0987,75.5706, 1];
    var buy_currency=0;
    var sell_currency=2;
    
    $('.currency-block').on('click',function(){
      var t=$(this);
      var type=t.parent().attr('id');
      var curr_t=t.text();
      var curr=2;
      if( curr_t=='$' )curr=0;
      if( curr_t=='€' )curr=1;
    
      console.log(curr_t+' curr '+curr);
      $('#'+type+' .currency-block').removeClass('currency-active');
      t.addClass('currency-active');
      if(type=='i_sell'){ sell_currency=curr; }
      if(type=='i_buy'){ buy_currency=curr; }
      recount_form();
    } );
    
    
    $("#currency-field").on("keyup",function(){
     var t=$(this);
     var v=t.val().replace(new RegExp('[^0-9]','g'), '');
     console.log("event change  value: "+v);
    
     if(isNaN(v)){ t.val(''); return; }
    // t.val(roundPlus(v,2));
     t.val(v);
    
     recount_form();
    });
    
    function recount_form(){
    
      var curs=1;
      curs=aCurs[buy_currency]/aCurs[sell_currency];
      $('#curs').text(roundPlus(curs,4));
      console.log(buy_currency+' / '+sell_currency+ ' '+curs+'*'+gv("currency-field"));
      $('#summ').text(roundPlus(curs*gv("currency-field"),2));
    
    
    }
    
    function roundPlus(x, n) { //x - число, n - количество знаков
      if(isNaN(x) || isNaN(n)) return false;
      var m = Math.pow(10,n);
      return Math.round(x*m)/m;
    }
    
    function gv( id ){ 
    
     var v=Number($("#"+id).val());
     if(isNaN(v)){  return 0;  }
     return v; 
    }
    
    function rr( str ){ 
     return ( roundPlus(str,2) +'').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    }
    */

    $(document).ready(function () {
      recount_form();
    });
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1200,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1550,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="strategy-block">
    <div class="strategy-header">Примеры наших лучших идей</div>

    <div class="brokerage-block-marg">
      <div class="multiple-item slick-initialized slick-slider">
        <div class="slick-prev slick-arrow" style="display: block"></div>

        <div aria-live="polite" class="slick-list draggable">
          <div
            class="slick-track"
            style="opacity: 1; width: 10206px; left: -2494.8px"
            role="listbox"
          >
            <div
              class="strategy-block-slide slick-slide slick-cloned"
              data-slick-index="-3"
              aria-hidden="true"
              tabindex="-1"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="1.2s"
                data-wow-offset="200"
                style="animation-delay: 1.2s; visibility: visible"
              >
                <div class="strategy-item strategy04">
                  <div class="point-header">Акции Wells Fargo</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Июнь 2020 – апрель 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 65% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-cloned"
              data-slick-index="-2"
              aria-hidden="true"
              tabindex="-1"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="1.2s"
                data-wow-offset="200"
                style="animation-delay: 1.2s; visibility: visible"
              >
                <div class="strategy-item protected02">
                  <div class="point-header">Акции Warrior Met Coal</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Февраль 2020 - октябрь 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 39% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта - USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-cloned"
              data-slick-index="-1"
              aria-hidden="true"
              tabindex="-1"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="1.2s"
                data-wow-offset="200"
                style="animation-delay: 1.2s; visibility: visible"
              >
                <div class="strategy-item protected01">
                  <div class="point-header">Акции Bunge LTD</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Август 2021 – Январь 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 31% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="0"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide00"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy07">
                  <div class="point-header">Alphabet Inc. (GOOG US)</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    15.09.2022 - 09.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 50,3% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="1"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide01"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy06">
                  <div class="point-header">
                    Облигации Газпром Капитал (Газпром ЗО-37- 1Д)
                  </div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    02.10.2023 - 15.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 13,18% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="2"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide02"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy05">
                  <div class="point-header">
                    Облигации Газпром Капитал (Газпром-ЗО-31-1Д)
                  </div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    02.10.2023 - 15.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 16,91% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="3"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide03"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy04">
                  <div class="point-header">GLD US</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    23.11.2023 - 12.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 19% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-current slick-active"
              data-slick-index="4"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide04"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy03">
                  <div class="point-header">Raytheon Technologies</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Октябрь 2022 – Ноябрь 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 18% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-active"
              data-slick-index="5"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide05"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy02">
                  <div class="point-header">Акции Petrobras</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Декабрь 2021 – Октябрь 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 86% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-active"
              data-slick-index="6"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide06"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy01">
                  <div class="point-header">Акции Cigna Corporation</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Декабрь 2021 – Октябрь 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 43% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="7"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide07"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy08">
                  <div class="point-header">Акции Яндекса</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Июнь 2022 – Июль 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 53% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – RUB</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="8"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide08"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy07">
                  <div class="point-header">Акции Vertex Pharmaceuticals</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Декабрь 2020 – Май 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 28,3% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="9"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide09"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy06">
                  <div class="point-header">Акции Bristol Myers Squibb</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Декабрь 2020 – Май 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность - 34,1% за период (с учетом дивидендов)
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="10"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide010"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy05">
                  <div class="point-header">Акции Johnson&amp;Johnson</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Ноябрь 2020 – Апрель 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 38% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="11"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide011"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy01">
                  <div class="point-header">Euronav</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Май 2020 – Апрель 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    59% за период (с учетом дивидендов)
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="12"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide012"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy02">
                  <div class="point-header">Raytheon Technologies</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Декабрь 2021 – Март 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 29% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="13"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide013"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy03">
                  <div class="point-header">Standard Chartered PLC</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Декабрь 2021 – Февраль 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 31% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="14"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide014"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy04">
                  <div class="point-header">
                    Акции Koninklijke Ahold Delhaize
                  </div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Июнь 2021 – ноябрь 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 31% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="15"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide015"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.3s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy01">
                  <div class="point-header">Акции ММК</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Июль 2021 – сентябрь 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 21% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – RUB</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="16"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide016"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.6s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.6s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy02">
                  <div class="point-header">Еврооблигации Car Inc-21</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Апрель 2020 – февраль 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 72% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="17"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide017"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.9s"
                data-wow-offset="200"
                style="
                  animation-delay: 0.9s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy03">
                  <div class="point-header">Акции Lenovo Group</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Октябрь 2020 – февраль 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 85% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="18"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide018"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="1.2s"
                data-wow-offset="200"
                style="
                  animation-delay: 1.2s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item strategy04">
                  <div class="point-header">Акции Wells Fargo</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Июнь 2020 – апрель 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 65% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="19"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide019"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="1.2s"
                data-wow-offset="200"
                style="
                  animation-delay: 1.2s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item protected02">
                  <div class="point-header">Акции Warrior Met Coal</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Февраль 2020 - октябрь 2021
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 39% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта - USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="20"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide020"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="1.2s"
                data-wow-offset="200"
                style="
                  animation-delay: 1.2s;
                  animation-name: visible-scale;
                  visibility: visible;
                "
              >
                <div class="strategy-item protected01">
                  <div class="point-header">Акции Bunge LTD</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    Август 2021 – Январь 2022
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 31% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-cloned"
              data-slick-index="21"
              aria-hidden="true"
              tabindex="-1"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="animation-delay: 0.3s; visibility: visible"
              >
                <div class="strategy-item strategy07">
                  <div class="point-header">Alphabet Inc. (GOOG US)</div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    15.09.2022 - 09.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 50,3% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-cloned"
              data-slick-index="22"
              aria-hidden="true"
              tabindex="-1"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="animation-delay: 0.3s; visibility: visible"
              >
                <div class="strategy-item strategy06">
                  <div class="point-header">
                    Облигации Газпром Капитал (Газпром ЗО-37- 1Д)
                  </div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    02.10.2023 - 15.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 13,18% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-cloned"
              data-slick-index="23"
              aria-hidden="true"
              tabindex="-1"
              style="width: 378px"
            >
              <div
                class="wow company-anim"
                data-wow-delay="0.3s"
                data-wow-offset="200"
                style="animation-delay: 0.3s; visibility: visible"
              >
                <div class="strategy-item strategy05">
                  <div class="point-header">
                    Облигации Газпром Капитал (Газпром-ЗО-31-1Д)
                  </div>
                </div>
                <div class="strategy-info">
                  <div class="strategy-info-item s-icon01">
                    02.10.2023 - 15.04.2024
                  </div>
                  <div class="strategy-info-item s-icon02">
                    Доходность – 16,91% за период
                  </div>
                  <div class="strategy-info-item s-icon03">Валюта – USD</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="slick-next slick-arrow" style="display: block"></div>
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: none"
        >
          <div class="about-anot-over">
            Инвестиционное консультирование
            <div>
              <a href="/advisory.html" class="company-anot-more"
                >Узнайте больше</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: none"
        >
          <div class="about-anot-over">
            Анализ портфеля
            <div>
              <div class="company-anot-more specific-click">Узнайте больше</div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoblock-black advantage-infoblock">
        <div class="infoblock-header">Инвестиционное консультирование</div>
        <div class="infoblock-item smallitem">
          Инвестор лично управляет своим портфелем, самостоятельно принимая
          решения о всех операциям по счету
        </div>
        <div class="infoblock-item smallitem">
          Мы предоставляем инвестору качественную аналитику и даем рекомендации
          по актуальным идеям к покупке или продаже
        </div>
        <div class="infoblock-item smallitem">
          Доходность от управления зависит от своевременного реагирования на
          рыночные тренды и грамотного подхода к риску
        </div>
        <div class="infoblock-transp"></div>
      </div>
      <div class="infoblock-black specific-infoblock">
        <div class="infoblock-header">Анализ портфеля</div>
        <div class="infoblock-item smallitem">
          По вашему запросу мы проводим всесторонний качественный и
          количественный анализ состава, структуры и результатов управления
          вашим портфелем третьими лицами
        </div>
        <div class="infoblock-item smallitem">
          По итогам анализа вы получаете развернутый отчет с рекомендациями о
          том, как можно улучшить управление вашим портфелем
        </div>
        <div class="infoblock-item smallitem">
          Услугой можно воспользоваться один раз или использовать регулярно
        </div>
        <div class="infoblock-transp"></div>
      </div>
    </div>
    <div class="col50">
      <div class="account-bg">
        <div class="company-header">
          <div class="double-border">
            Дополнительные услуги для опытных инвесторов, которые самостоятельно
            управляют портфелем
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.2s"
      data-wow-offset="100"
      style="animation-delay: 0.2s; animation-name: none"
    >
      <div class="advantage-block nomargin doc-link">Скачать QUIK</div>
      <div class="advantage-info">
        <a href="/docs/GIN_QUIK_RU.zip" class="button-link auto-h"
          >Скачать QUIK-терминал</a
        >
        <br /><br /><a href="/docs/quik_key.zip" class="button-link auto-h"
          >Скачать генератор ключей QUIK</a
        >
      </div>
    </div>

    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="animation-delay: 0.6s; animation-name: none"
    >
      <div class="advantage-block nomargin doc-link">
        Регламент брокерского обслуживания
      </div>
      <div class="advantage-info">
        Актуально с 01.03.2024 по 16.06.2024 <br /><br />Дата размещения
        15.02.2024 <br /><br /><a
          href="/docs/reglament_2024_03_01.pdf"
          class="button-link-doc"
          >Скачать</a
        >
      </div>
    </div>

    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="animation-delay: 0.6s; animation-name: none"
    >
      <div class="advantage-block nomargin doc-link">
        Тарифы брокерского обслуживания
      </div>
      <div class="advantage-info">
        Актуально с 01.03.2024 по настоящее время <br /><br />Дата размещения
        17.04.2024 <br /><br /><a
          href="/docs/Tarify_brokerskie_s_01_marta_2024.pdf"
          class="button-link-doc"
          >Скачать</a
        >
      </div>
    </div>

    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="animation-delay: 0.6s; animation-name: none"
    >
      <div class="advantage-block nomargin doc-link">
        Список ликвидных ценных бумаг
      </div>
      <div class="advantage-info">
        <a href="/marginal_transactions.html" class="button-link"
          >Узнайте больше</a
        >
      </div>
    </div>
  </div>

  <div class="about-advantage" style="padding-top: 0; padding-bottom: 0">
    <div class="dactive-comment">
      * ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» может совершать по поручению клиентов
      сделки (в том числе внебиржевые) с ценными бумагами через вышестоящих
      брокеров (в том числе иностранных юридических лиц):
      <br />Публичное акционерное общество «СПБ Банк» (ПАО «СПБ Банк»)
      <br />Акционерное общество «Райффайзенбанк» (АО «Райффайзенбанк)
      <br />Открытое акционерное общество «Армброк» (ОАО «АРМБРОК», ARMBROK Open
      Joint Stock Company) <br />Закрытое Акционерное Общество
      «Промышленно-Строительный Банк» (ЗАО «Ардшинбанк», «Industrial
      Construction Bank» Closed Joint-Stock Company) <br />Указанный перечень не
      является исчерпывающим. <br /><br />Клиент до направления ООО Концерн
      «ДЖЕНЕРАЛ-ИНВЕСТ» поручения на совершение сделки с ценными бумагами обязан
      самостоятельно ознакомиться с тарифами ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ , в
      том числе с тарифами вышестоящих брокеров по ссылкам: <br />ПАО «СПБ Банк»
      <a
        href="https://spbbank.ru/ru/tariffs/"
        rel="nofollow"
        style="color: #000"
        target="_blank"
        >https://spbbank.ru/ru/tariffs/</a
      >
      <br />Акционерное общество «Райффайзенбанк»
      <a
        href="https://www.raiffeisen.ru/retail/invest/broker/"
        rel="nofollow"
        style="color: #000"
        target="_blank"
        >https://www.raiffeisen.ru/retail/invest/broker/</a
      >
      <br />ОАО «АРМБРОК»
      <a
        href="https://www.raiffeisen.ru/retail/invest/broker/"
        rel="nofollow"
        style="color: #000"
        target="_blank"
        >https://www.armbrok.am/services/brokerage</a
      >
      <br />ЗАО «Ардшинбанк»
      <a
        href="https://www.ardshinbank.am/ru/content/%D0%B8%D0%BD%D0%B2%D0%B5%D1%81%D1%82%D0%B8%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5-%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80%D1%81%D0%BA%D0%B8%D0%B5-%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8"
        rel="nofollow"
        style="color: #000"
        target="_blank"
        >https://www.ardshinbank.am/</a
      >
      <br /><br />ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» уведомляет вас о том, что до
      подачи поручений на совершение гражданско-правовых сделок с ценными
      бумагами и (или) на заключение договоров, являющихся производными
      финансовыми инструментами посредством информационно-торговой системы QUIK
      MP «Брокер», физическому лицу-получателю финансовых услуг необходимо
      ознакомиться с информацией о финансовых инструментах и связанных с ними
      рисками. Информация для ознакомления и использования размещена на сайте
      ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» в информационно-телекоммуникационной сети
      Интернет в разделе Услуги – Информация для клиентов и доступна по
      <a href="/clients-information.html">ссылке</a>
    </div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Получить больше информации</div>
    <div class="form-block-inline">
      <div class="feedback-comment">
        Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
      </div>
      <form
        name="feedback"
        id="feedback"
        method="post"
        action="/feedback/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Ваше имя"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>
    
          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
