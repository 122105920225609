<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class="logo-block-bg-full"></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Лицензии</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/licenses/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about.html">О компании</a>

    &nbsp;/&nbsp;Лицензии
  </div>

  <div id="logo-block-bg-b" class="hidden"></div>
</div>

<div id="column" class="visible">
  <script>
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: false,
        autoplaySpeed: 6000,
        speed: 1200,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="quote-block-gb">
    <div id="team-group">
      <div id="team-block" style="opacity: 1; position: relative; top: 0">
        <div class="licenses-block-marg">
          <div class="multiple-item slick-initialized slick-slider">
            <div class="slick-prev slick-arrow" style="display: block"></div>

            <div aria-live="polite" class="slick-list draggable">
              <div
                class="slick-track"
                role="listbox"
                style="opacity: 1; width: 3910px; left: -1173px"
              >
                <div
                  class="team-item slick-slide slick-cloned"
                  data-slick-index="-3"
                  aria-hidden="true"
                  tabindex="-1"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_1.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление деятельности по управлению
                      ценными бумагами
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-cloned"
                  data-slick-index="-2"
                  aria-hidden="true"
                  tabindex="-1"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_2.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление дилерской деятельности
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-cloned"
                  data-slick-index="-1"
                  aria-hidden="true"
                  tabindex="-1"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_3.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление брокерской деятельности
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-current slick-active"
                  data-slick-index="0"
                  aria-hidden="false"
                  tabindex="-1"
                  role="option"
                  aria-describedby="slick-slide00"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/depositary_lic.pdf" tabindex="0"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление депозитарной деятельности
                    </div>
                    <br />Актуально с 21.06.2011 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-active"
                  data-slick-index="1"
                  aria-hidden="false"
                  tabindex="-1"
                  role="option"
                  aria-describedby="slick-slide01"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_1.pdf" tabindex="0"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление деятельности по управлению
                      ценными бумагами
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-active"
                  data-slick-index="2"
                  aria-hidden="false"
                  tabindex="-1"
                  role="option"
                  aria-describedby="slick-slide02"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_2.pdf" tabindex="0"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление дилерской деятельности
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide"
                  data-slick-index="3"
                  aria-hidden="true"
                  tabindex="-1"
                  role="option"
                  aria-describedby="slick-slide03"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_3.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление брокерской деятельности
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-cloned"
                  data-slick-index="4"
                  aria-hidden="true"
                  tabindex="-1"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/depositary_lic.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление депозитарной деятельности
                    </div>
                    <br />Актуально с 21.06.2011 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-cloned"
                  data-slick-index="5"
                  aria-hidden="true"
                  tabindex="-1"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_1.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление деятельности по управлению
                      ценными бумагами
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-cloned"
                  data-slick-index="6"
                  aria-hidden="true"
                  tabindex="-1"
                  style="width: 321px"
                >
                  <div class="licenses-item-block">
                    <div class="licenses-over">
                      <a href="/docs/Liz_2.pdf" tabindex="-1"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление дилерской деятельности
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
              </div>
            </div>

            <div class="slick-next slick-arrow" style="display: block"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Получить больше информации</div>
    <div class="form-block-inline">
      <div class="feedback-comment">
        Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
      </div>
      <form
        name="feedback"
        id="feedback"
        method="post"
        action="/feedback/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Ваше имя"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>

          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
