import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './UI/header/header.component';
import { AssetManagementComponent } from './services/asset-management/asset-management.component';
import { FooterComponent } from './UI/footer/footer.component';
import { BrokerageServicesComponent } from './services/brokerage-services/brokerage-services.component';
import { DepositaryComponent } from './services/depositary/depositary.component';
import { AdvisoryComponent } from './services/advisory/advisory.component';
import { ClientsInformationComponent } from './services/clients-information/clients-information.component';
import { DeliclubComponent } from './services/deliclub/deliclub.component';
import { AboutComponent } from './about/about.component';
import { HistoryComponent } from './about/history/history.component';
import { TeamComponent } from './about/team/team.component';
import { HowToBecomeAClientComponent } from './about/how-to-become-a-client/how-to-become-a-client.component';
import { AffiliateProgramsComponent } from './about/affiliate-programs/affiliate-programs.component';
import { LicensesComponent } from './about/licenses/licenses.component';
import { SocialResponsibilityComponent } from './about/social-responsibility/social-responsibility.component';
import { PressComponent } from './press/press.component';
import { ContactsComponent } from './contacts/contacts.component';
import { NewsComponent } from './press/news/news.component';
import { EventsComponent } from './press/events/events.component';
import { PressNewsComponent } from './press/press-news/press-news.component';
import { ContactsForMediaComponent } from './press/contacts-for-media/contacts-for-media.component';
import { CareerComponent } from './about/career/career.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    AssetManagementComponent,
    FooterComponent,
    BrokerageServicesComponent,
    DepositaryComponent,
    AdvisoryComponent,
    ClientsInformationComponent,
    DeliclubComponent,
    AboutComponent,
    HistoryComponent,
    TeamComponent,
    HowToBecomeAClientComponent,
    AffiliateProgramsComponent,
    LicensesComponent,
    SocialResponsibilityComponent,
    PressComponent,
    ContactsComponent,
    NewsComponent,
    EventsComponent,
    PressNewsComponent,
    ContactsForMediaComponent,
    CareerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
