<div id="column-slide-second" class="visible">
  <div id="logo-block-bg"></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>История компании</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/history/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about.html">О компании</a>

    &nbsp;/&nbsp;История компании
  </div>

  <div id="logo-block-bg-b"></div>
</div>

<div id="column" class="visible">
  <script>
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: false,
        infinite: false,
        speed: 1200,
        dots: true,
        dotsClass: "year-dots",
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

      $(".multiple-item").mousewheel(function (e) {
        if (e.deltaY < 0) {
          if (
            $(this).slick("slickCurrentSlide") ==
            $(this).find(".slide").length - 1
          ) {
            return;
          }

          e.preventDefault();
          $(this).slick("slickNext");
        } else {
          if ($(this).slick("slickCurrentSlide") == 0) {
            return;
          }

          e.preventDefault();
          $(this).slick("slickPrev");
        }
      });
    };
  </script>

  <div class="history-block">
    <div class="brokerage-block-marg">
      <div class="multiple-item slick-initialized slick-slider" role="toolbar">
        <div
          class="slick-prev slick-arrow slick-disabled"
          aria-disabled="true"
          style="display: block"
        ></div>

        <div aria-live="polite" class="slick-list draggable">
          <div
            class="slick-track"
            role="listbox"
            style="opacity: 1; width: 5936px; left: 0px"
          >
            <div
              class="strategy-block-slide slick-slide slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide00"
              style="width: 371px"
            >
              <div class="strategy-item history02">
                <div class="year-header">2009</div>
              </div>
              <div class="strategy-info">
                Зарегистрирован бренд General Invest. Центральный банк России
                выдает General Invest лицензии на управление ценными бумагами,
                осуществление дилерской и брокерской деятельности.
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-active"
              data-slick-index="1"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide01"
              style="width: 371px"
            >
              <div class="strategy-item history03">
                <div class="year-header">2010</div>
              </div>
              <div class="strategy-info">
                General Invest становится полноправным членом Национальной
                ассоциации участников фондового рынка (НАУФОР)
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide slick-active"
              data-slick-index="2"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide02"
              style="width: 371px"
            >
              <div class="strategy-item history04">
                <div class="year-header">2011</div>
              </div>
              <div class="strategy-info">
                General Invest становится лауреатом премии «Финансовый олимп» в
                номинации «За привлечение иностранных инвестиций в Россию»
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="3"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide03"
              style="width: 371px"
            >
              <div class="strategy-item history05">
                <div class="year-header">2012</div>
              </div>
              <div class="strategy-info">
                Проходит первый российско-итальянский форум «Россия: новые
                возможности для предприятий и инвесторов», организованный
                ММВБ-РТС и General Invest
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="4"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide04"
              style="width: 371px"
            >
              <div class="strategy-item history06">
                <div class="year-header">2013</div>
              </div>
              <div class="strategy-info">
                General Invest открывает офис в Калининграде
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="5"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide05"
              style="width: 371px"
            >
              <div class="strategy-item history01">
                <div class="year-header">2014</div>
              </div>
              <div class="strategy-info">
                Агентство «Рус-Рейтинг» присваивает General Invest рейтинг
                надежности по национальной шкале «BBB», по международной шкале —
                «BB», прогноз стабильный
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="6"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide06"
              style="width: 371px"
            >
              <div class="strategy-item history02">
                <div class="year-header">2015</div>
              </div>
              <div class="strategy-info">
                General Invest входит в топ-10 рейтинга Forbes «Лучшие частные
                банки и управляющие благосостоянием» и предоставляет клиентам
                возможность торговать еврооблигациями на Московской Бирже, а
                «Рус-Рейтинг» поднимает рейтинг надежности компании на две
                ступени — до уровня «А-»
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="7"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide07"
              style="width: 371px"
            >
              <div class="strategy-item history03">
                <div class="year-header">2016</div>
              </div>
              <div class="strategy-info">
                General Invest становится компанией года в сфере Wealth
                Management (премия «Финансовая элита России») и лучшей
                инвестиционной компанией года по версии SPEAR’S, а должность
                генерального директора занимает Алексей Ищенко
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="8"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide08"
              style="width: 371px"
            >
              <div class="strategy-item history04">
                <div class="year-header">2017</div>
              </div>
              <div class="strategy-info">
                General Invest открывает офисы в Санкт-Петербурге, Екатеринбурге
                и Казани, входит в топ-10 управляющих активами в России (рэнкинг
                Extel Pan-Europe 2017 Survey) и запускает партнерскую программу
                с ключевыми сотрудниками компании.
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="9"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide09"
              style="width: 371px"
            >
              <div class="strategy-item history05">
                <div class="year-header">2018</div>
              </div>
              <div class="strategy-info">
                General Invest открывает офисы в Тюмени и Краснодаре, становится
                патроном ГМИИ им. А. С. Пушкина, входит в топ-5 управляющих
                активами в России (рэнкинг Extel Pan-Europe 2018 Survey) и
                получает звание лучшей инвестиционной компании года по версии
                SPEAR’S
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="10"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide010"
              style="width: 371px"
            >
              <div class="strategy-item history06">
                <div class="year-header">2019</div>
              </div>
              <div class="strategy-info">
                General Invest входит в список крупнейших брокерских компаний
                России по версии «КоммерсантЪ» и в топ-3 управляющих активами в
                России (рэнкинг Extel Pan-Europe 2019 Survey)
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="11"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide011"
              style="width: 371px"
            >
              <div class="strategy-item history01">
                <div class="year-header">2020</div>
              </div>
              <div class="strategy-info">
                General Invest торжественно открывает офис в Новосибирске
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="12"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide012"
              style="width: 371px"
            >
              <div class="strategy-item history02">
                <div class="year-header">2021</div>
              </div>
              <div class="strategy-info">
                General Invest получает звание лучшей инвестиционной компании по
                версии SPEAR’s, а генеральный директор Алексей Ищенко получает
                награду в номинации «Инвестиционный консультант» года на премии
                SPEAR’S Russia Wealth Navigator Awards
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="13"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide013"
              style="width: 371px"
            >
              <div class="strategy-item history04">
                <div class="year-header">2022</div>
              </div>
              <div class="strategy-info">
                <a
                  href="/news/268.html"
                  style="text-decoration: underline"
                  tabindex="-1"
                  >General Invest вошла в рейтинг ТОП-50 лучших работодателей
                  России</a
                >
                по версии Head Hunter. Компания признана одним из лучших
                работодателей нашей страны, заняв 21-е место среди 241-й
                компании численностью от 100 до 250 сотрудников.
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="14"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide014"
              style="width: 371px"
            >
              <div class="strategy-item history05">
                <div class="year-header">2022</div>
              </div>
              <div class="strategy-info">
                General Invest названа
                <a
                  href="/news/297.html"
                  style="text-decoration: underline"
                  tabindex="-1"
                  >лучшей инвестиционной компанией года на премии WEALTH
                  Navigator Awards 2022</a
                >
              </div>
            </div>
            <div
              class="strategy-block-slide slick-slide"
              data-slick-index="15"
              aria-hidden="true"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide015"
              style="width: 371px"
            >
              <div class="strategy-item history06">
                <div class="year-header">2023</div>
              </div>
              <div class="strategy-info">
                <a
                  href="/news/298.html"
                  style="text-decoration: underline"
                  tabindex="-1"
                  >General Invest открыла новый офис в центре Москвы</a
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="slick-next slick-arrow"
          style="display: block"
          aria-disabled="false"
        ></div>
        <ul class="year-dots" style="display: block" role="tablist">
          <li
            class="slick-active"
            aria-hidden="false"
            role="presentation"
            aria-selected="true"
            aria-controls="navigation00"
            id="slick-slide00"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              1
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation01"
            id="slick-slide01"
            class=""
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              2
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation02"
            id="slick-slide02"
            class=""
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              3
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation03"
            id="slick-slide03"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              4
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation04"
            id="slick-slide04"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              5
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation05"
            id="slick-slide05"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              6
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation06"
            id="slick-slide06"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              7
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation07"
            id="slick-slide07"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              8
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation08"
            id="slick-slide08"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              9
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation09"
            id="slick-slide09"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              10
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation010"
            id="slick-slide010"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              11
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation011"
            id="slick-slide011"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              12
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation012"
            id="slick-slide012"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              13
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation013"
            id="slick-slide013"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              14
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation014"
            id="slick-slide014"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              15
            </button>
          </li>
          <li
            aria-hidden="true"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation015"
            id="slick-slide015"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabindex="0"
            >
              16
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="feedback-block text-center">
    <div class="feedback-header-inline">Получить больше информации</div>
    <div class="form-block-inline">
      <div class="feedback-comment">
        Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
      </div>
      <form
        name="feedback"
        id="feedback"
        method="post"
        action="/feedback/"
        enctype="multipart/form-data"
      >
        <div class="form-block-center">
          <input
            type="text"
            id="name"
            name="name"
            value=""
            size="50"
            placeholder="Ваше имя"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="phone"
            name="phone"
            value=""
            size="50"
            placeholder="Телефон"
          />
          <div class="form-error"></div>
          <input
            type="text"
            id="email"
            name="email"
            value=""
            size="50"
            placeholder="E-mail"
          />
          <div class="form-error"></div>
          <div class="check-block">
            <div class="check-name">
              Даю согласие на
              <a href="/consent.html" target="_blank"
                >обработку персональных данных</a
              >
              <input type="checkbox" id="agree" name="agree" value="0" />
              <div class="form-error check-error"></div>
            </div>
          </div>
     
          <input
            type="submit"
            id="submit_form"
            name="submit_form"
            value="Отправить"
            size=""
          />
          <div class="form-error"></div>
        </div>
      </form>
      <div id="form-result"></div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
